@import "src/styles/includes/colors";

.ctoggler {
  height: 10px;
  font-size: 16px;
  line-height: 100%;
  padding: 0 10px;
  border: none;
  border-radius: 700px;
  background: $gray-3;
  position: relative;
  width: 30px;
  cursor: pointer;
  outline: none;
  transition: background 0.2s ease;

  &:after {
    content: "";
    position: absolute;
    top: -3px;
    left: -6px;
    width: 16px;
    height: 16px;
    background: $gray-2;
    border-radius: 50%;
    transition:
      transform 0.2s ease,
      background 0.2s ease;
  }

  &--react {
    margin-right: 12px;

    &-wrapper {
      margin-left: 0;

      &-parent {
        padding: 8px;
      }
    }

    &-icon {
      top: 50% !important;
      transform: translateY(-50%);
    }
  }

  &.active {
    background: $turquoise;

    &:after {
      transform: translateX(26px);
      background: $turquoise-dark;
    }
  }
}
