.pb {
  &-20 {
    padding-bottom: 20px !important;
  }

  &-40 {
    padding-bottom: 40px !important;
  }
}

.mt {
  &-10 {
    margin-top: 10px !important;
  }

  &-20 {
    margin-top: 20px !important;
  }

  &-24 {
    margin-top: 24px !important;
  }

  &-30 {
    margin-top: 30px !important;
  }

  &-40 {
    margin-top: 40px !important;
  }
}

.mb {
  &-5 {
    margin-bottom: 5px !important;
  }

  &-10 {
    margin-bottom: 10px !important;
  }

  &-20 {
    margin-bottom: 20px !important;
  }

  &-30 {
    margin-bottom: 30px !important;
  }

  &-40 {
    margin-bottom: 40px !important;
  }
}
