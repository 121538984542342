$cyan: #00a8c2;
$turquoise: #dcf9fb;
$turquoise-dark: #0dc4e0;
$turquoise-dark-2: #00a8c2;
$gray-0: #697b8c;
$gray-1: #8596a6;
$gray-2: #b8c4cc;
$gray-3: #d8e4eb;
$gray-4: #f0f5f7;
$gray-f-0: #6f7270;
$gray-f-1: #c4c4c4;
$gray: #ebebeb;
$dark: #3b3b3b;
$green: #00b259;
$red: #ff513d;
$blue: #627eea;
$light-blue: #d9ecff;
$gold: #ebb50b;
