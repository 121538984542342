@mixin custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #dfdfdf #dcf8fb;

  &::-webkit-scrollbar {
    background: #dcf8fb;
    width: 7px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #dfdfdf;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
}

@mixin text-clamped($max-lines) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: $max-lines;
  word-break: break-word;
}
