@import "../includes/colors";
@import "../includes/mixins";

.all-assets {
  thead {
    background: #ffffff;
  }

  &__hide {
    font-weight: bold;
    font-size: 14px;
    color: $turquoise-dark;
  }

  &__top-info {
    border-bottom: none;

    .venus-earned {
      &-title,
      &-value {
        display: flex;
        align-items: center;

        .title,
        .value,
        .collect {
          padding-top: 2px;
          font-size: 14px;
          line-height: 1.5;
          display: block;
        }
      }

      &-title {
        .coin {
          min-width: 24px;
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }

      &-value {
        .collect {
          color: $turquoise-dark;
          font-weight: 500;
        }
      }
    }
  }

  &__apy {
    display: flex;
    align-items: center;

    .text {
      margin-right: 16px;
      font-size: 14px;
      line-height: 1.5;
      padding-top: 2px;

      .fire {
        transition: opacity 0.2s ease;

        &.invisible {
          opacity: 0;
        }
      }
    }
  }

  &__asset {
    > div {
      display: flex;
      align-items: center;
    }

    .symbol-percentage {
      span {
        display: block;
      }

      .percentage {
        font-size: 12px;
        line-height: 145%;
        color: $gray-0;
      }
    }

    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 155%;
      color: #000000;
    }
  }

  &__amount,
  &__price,
  &__total {
    font-style: normal;
    font-size: 12px;
    line-height: 145%;
    font-weight: 500;
  }

  &__total,
  &__amount {
    color: #697b8c;

    span {
      display: block;
    }

    .usd {
      color: black;
      font-size: 14px;
    }
  }

  &__buttons {
    div {
      display: flex;
      align-items: center;
    }

    button {
      white-space: nowrap;
    }

    .save {
      margin-right: 35px;
      background: $turquoise-dark;
      border-radius: 30px;
      padding: 7px 24px;
      transition: background 0.2s ease;
      color: #000000;
      background: linear-gradient(90deg, #b6e82f 0%, #50e0ed 100%);
      cursor: pointer;
      &:hover {
        border: solid 1px transparent;
        background-image: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0)
          ),
          linear-gradient(101deg, #b6e82f, #50e0ed);
        background-origin: border-box;
        background-clip: content-box, border-box;
        -webkit-filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.25));
        filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.25));
      }
    }

    .save,
    .withdraw {
      font-weight: bold;
      font-size: 14px;
      line-height: 85%;
      text-align: center;
    }

    .withdraw {
      color: $turquoise-dark;
      transition: color 0.2s ease;

      &:hover {
        color: $turquoise-dark-2;
      }
    }
  }

  &__price {
    &.plus {
      color: $green;
    }

    &.minus {
      color: $red;
    }
  }

  &__table {
    min-height: 200px;
    position: relative;
    overflow-y: auto;
    width: 100%;

    &--filled {
      min-height: unset;
    }

    @include custom-scrollbar;
  }

  &__show {
    padding: 14px 30px;
    color: $turquoise-dark;
    background: #ffffff;
    border: 1px solid $gray-3;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 85%;
    text-align: center;
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 20px;
    transition:
      color 0.2s ease,
      background 0.2s ease,
      border-color 0.2s ease;

    &:hover {
      color: #fff;
      background: $turquoise-dark;
      border-color: $turquoise-dark;
    }
  }
}
