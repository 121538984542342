.container {
  width: 100%;
  //max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px 60px;
}

@media (max-width: 1350px) {
  .container {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
