.page-container {
  padding-left: 238px;
  padding-right: 288px;
  padding-top: 36px;
  width: 100%;
  min-height: 100vh;

  &--expanded {
    @media (min-width: 1101px) {
      //padding-left: 128px;
    }
  }
}

@media (max-width: 1350px) {
  .page-container {
    padding-left: 99px;
    padding-top: 99px;
    padding-right: 77px;
  }
}

@media (max-width: 870px) {
  .page-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 600px) {
  .page-container {
    padding-left: 20px;
    padding-top: 90px;
    padding-right: 20px;
  }
}
