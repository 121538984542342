@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/Geometria.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/Geometria-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/Geometria-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/Geometria-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Geometria";
  src: url("../../assets/fonts/Geometria-ExtraBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
}
