.warning {
  padding: 0.75em;
  border-radius: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;

  img {
    fill: rgb(217, 48, 78);
    margin-right: 10px;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }

  span {
    font-size: 12px;
    line-height: 1.5;
  }

  h2 {
    font-size: 0.875em;
    line-height: 1.5;
  }

  ol {
    li {
      font-size: 12px;
      line-height: 1.5;
      color: rgb(118, 128, 143);
      margin-top: 0.333333em;

      span {
        white-space: nowrap;
      }
    }
  }

  &--error {
    background: rgba(217, 48, 78, 0.1);
    border: 1px solid rgb(217, 48, 78);
  }

  &--warning,
  &--reminder,
  &--info {
    background: #f6fdfe;
    border: 1px solid #a8f0f6;
  }
}
