.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.from-bottom-enter-active,
.from-bottom-leave-active {
  transition: transform 0.5s ease-out;
}
.from-bottom-enter,
.from-bottom-leave-to {
  transform: translateY(105%);
}
.from-bottom-enter-to,
.from-bottom-leave {
  transform: translateY(0);
}

.quick-fade-enter-active,
.quick-fade-leave-active {
  transition: opacity 0.2s;
}
.quick-fade-enter,
.quick-fade-leave-to {
  opacity: 0;
}
