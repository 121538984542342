@import "./includes";
@import "./normalize.scss";

@import "./elements";
@import "./animations.scss";

@import "./global";

h1 {
  .small {
    font-size: 12px;
    white-space: nowrap;
  }
}

.container--large {
  max-width: 100%;
}

.price-info {
  color: #000 !important;
}

.custom-toast {
  text-align: center !important;
  justify-content: center !important;
}

.g-red-color {
  color: $red !important;
  font-weight: 500 !important;
}

.pending-btn {
  position: relative;

  &::after {
    content: "";
    width: 10%;
    height: 10%;
    border-radius: 20px;
    border: 10px solid #0dc4e0;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: airdropRing 1.1s infinite;
  }

  &:hover:after {
    display: none;
  }
}

.card {
  width: 100%;
  min-height: 50px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 2px 6px rgba(0, 111, 128, 0.08);

  > .vld-overlay {
    border-radius: 16px;
  }

  &--container {
    padding: 16px;
  }

  &__warning-text {
    color: $red;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 500;
  }

  &-options {
    display: flex;
    align-items: center;

    &__item {
      margin-left: 0 !important;
      margin-right: 16px !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;

    div {
      margin-left: 10px;
      padding-left: 10px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: $gray-2;
        width: 1px;
        height: 16px;
      }
    }
  }

  &__notice {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: $red;

    svg {
      min-width: 20px;
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    span {
      padding-top: 2px;
    }
  }

  &__top {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid $gray-3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    transition: border-color 0.2s ease;

    &--no-border {
      border-color: transparent;
    }

    &--disabled {
      opacity: 0.8;
      pointer-events: none;
    }

    &-close {
      margin-left: auto;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  &-top {
    &__heading {
      font-weight: normal;
      font-size: 14px;
      line-height: 155%;
      color: #000000;
    }
  }

  &__hr {
    width: 100%;
    height: 1px;
    background: $gray-4;
    margin: 20px 0;
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 155%;
    color: #000000;
  }

  &__table {
    border-collapse: collapse;
    width: 100%;

    th {
      text-align: left;
      padding: 12px 0px;
      border-bottom: 1px solid $gray-3;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 145%;
      color: $gray-1;
    }

    td {
      padding: 10px 24px;
      border-bottom: 1px solid $gray-4;
    }

    .sortable {
      > div {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
          margin-left: 5px;
        }
      }

      &.active {
        span {
          color: $turquoise-dark-2;
        }

        svg {
          path {
            fill: $turquoise-dark-2;
          }
        }
      }
    }

    &-double {
      padding: 20px;

      &-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .title,
        .value {
          font-size: 16px;
          line-height: 150%;
        }

        .title {
          color: $gray-0;
        }

        .value {
          color: black;
          font-weight: 500;
        }
      }
    }
  }

  &__content {
    position: relative;
    max-width: 450px;
    padding: 40px 30px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    &--full {
      max-width: unset;
      margin: 0;
    }
  }

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 155%;
    color: #697b8c;
    margin-bottom: 4px;
  }

  &__button {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
    padding: 18px 40px;
    background: #0dc4e0;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    transition: background 0.2s ease;

    &:hover {
      background: $turquoise-dark-2;
    }

    &.white {
      background: #ffffff;
      border: 1px solid $gray-3;
      color: $turquoise-dark;
      transition:
        border-color 0.2s ease,
        background-color 0.2s ease,
        color 0.2s ease;

      &:hover {
        background: #ffffff;
        border-color: $turquoise-dark;
      }

      &:disabled {
        background: #fff !important;
        border-color: $gray-3 !important;
      }
    }

    &.small {
      padding: 14px 30px 12px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;

      &:hover {
        background: #0dc4e0;
      }
    }

    &.active {
      &.white {
        border-color: $turquoise-dark;
        background: $turquoise-dark;
        color: white;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20px);
    margin: -10px;

    .card__button {
      width: calc(50% - 20px);
      margin: 10px;
    }
  }

  &__title-value {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    font-size: 14px;
    line-height: 155%;
    margin-left: 8px;

    .title {
      font-weight: normal;
      color: #697b8c;
      display: block;
    }

    .value {
      font-weight: 500;
      color: #00a8c2;
      margin-left: 10px;
    }
  }
}

#wallet-connect-mathwallet,
#wallet-connect-tokenpocket,
button[id="chain wallet"] {
  display: none;
}

.all-black {
  color: #000 !important;

  span,
  div {
    color: #000 !important;
  }

  svg {
    * {
      stroke: $gray-0;
    }
  }
}

.all-cyan {
  color: $cyan !important;
}

.black-stroke-all-in {
  * {
    stroke: $gray-0;
  }
}

.open-currency-select-button {
  transition: background 0.2s ease;

  &:hover,
  &:focus {
    background: $turquoise !important;
  }
}

.g-snackbar {
  z-index: 20000 !important;
}

.ai-fs {
  align-items: flex-start;
}

.black-text {
  color: #000 !important;
}

.black-svg {
  fill: #000 !important;
}

.input-bg {
  background: #f0f5f7 !important;
  color: #000 !important;
}

.focus-block-shadow {
  &--thick {
    &:focus:not(:disabled) {
      box-shadow:
        0 0 0 1px $cyan,
        0 0 0 4px $cyan !important;
    }
  }

  &--thin {
    &:focus:not(:disabled) {
      box-shadow:
        0 0 0 1px $cyan,
        0 0 0 2px $cyan !important;
    }
  }
}

[aria-label="Close the dialog"] {
  &:focus:not(:disabled) {
    box-shadow:
      0 0 0 1px $cyan,
      0 0 0 2px $cyan !important;
  }
}

@media (max-width: 700px) {
  .card {
    &__notice {
      display: none;
    }
  }
}

@media (max-width: 450px) {
  .card {
    &__buttons {
      .card__button {
        width: calc(100% - 20px);
      }
    }
  }
}

@media (max-width: 350px) {
  .input-max-button {
    padding: 0 3px !important;
  }
}
