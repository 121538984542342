@import "src/styles/includes/colors";
@import "src/styles/includes/mixins";

.card-select {
  position: relative;

  @mixin card-select-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
  }

  &__search {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000;
    position: relative;
    margin: 5px 20px 10px;
    height: 48px;

    &::placeholder {
      color: #8596a6;
    }

    input {
      width: 100%;
      height: 100%;
      background: $gray-4;
      padding: 0 60px 0 20px;
      border-radius: 8px;
      border: 1px solid transparent;
      transition: border-color 0.2s ease;

      &:hover {
        border-color: #b8c4cc;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    background: #f0f5f7;
    border-radius: 8px;
    @include card-select-text;
    padding: 16px 60px 16px 20px;
    cursor: pointer;
    transition:
      background 0.2s ease,
      border-color 0.2s ease;
    border: 1px solid transparent;

    .left {
      display: flex;
      align-items: center;

      &--with-right {
        margin-right: 10px;
      }
    }

    .right {
      color: $green;
      text-align: right;
      padding: 1px 8px;
      border-radius: 50px;
      font-size: 12px;
      font-weight: 500;
      background: rgba(2, 192, 118, 0.1);
      margin: 5px 0;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 20px;
      width: 10px;
      height: 10px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("/assets/svg/card-select-dropdown.svg");
      transform: translateY(-50%);
    }

    &:hover {
      border-color: #b8c4cc;
    }

    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
      margin-right: 10px;
    }
  }

  &__dropdown {
    position: absolute;
    left: 0;
    bottom: 8px;
    width: 100%;
    transform: translateY(100%) scale(0);
    transform-origin: top;
    padding: 18px 0 0;
    background: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow:
      0 20px 20px rgba(105, 123, 140, 0.1),
      0 10px 10px rgba(105, 123, 140, 0.05);
    overflow: hidden;

    ul {
      max-height: 300px;
      overflow-y: auto;
      margin-right: 4px;
      margin-bottom: 4px;
      @include custom-scrollbar;

      li {
        padding: 10px 20px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include card-select-text;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover:not(.selected) {
          background: #dde2e4;
        }

        .left {
          display: flex;
          align-items: center;
        }

        img {
          width: 16px;
          height: 16px;
          object-fit: contain;
          margin-right: 10px;
        }

        &.disabled {
          cursor: not-allowed;
          pointer-events: none;
          opacity: 0.5;
        }

        &.selected {
          color: $turquoise-dark;
          cursor: default;
        }
      }
    }
  }

  &.active {
    z-index: 10;

    .card-select {
      &__dropdown {
        transform: translateY(100%) scale(1);
      }

      &__top {
        background: #ffffff;
        border-color: $turquoise-dark;

        &:after {
          background-image: url("/assets/svg/card-select-dropdown-turquoise.svg");
        }
      }
    }
  }

  &.inactive {
    .card-select {
      &__top {
        cursor: default;
        padding-right: 20px;

        &:after {
          display: none;
        }

        &:hover {
          border-color: #d8e4eb !important;
        }
      }
    }
  }

  &.white {
    transition:
      box-shadow 0.2s ease,
      border-color 0.2s ease;
    border: 1px solid transparent;
    border-radius: 8px 8px 0 0;

    .card-select {
      &__top {
        box-shadow: 0 2px 6px rgba(0, 111, 128, 0.08);
        background: #ffffff;
        border: 1px solid #d8e4eb;
        transition:
          border-color 0.2s ease,
          box-shadow,
          0.2s ease;

        &:hover {
          border-color: #b8c4cc;
        }

        img {
          width: 40px;
          height: 40px;
          margin-right: 20px;
        }
      }

      &__dropdown {
        background: #ffffff;
        box-shadow: none;
        border-radius: 0 0 8px 8px;
        border: 1px solid #0dc4e0;
        width: calc(100% + 2px);
        margin-left: -1px;
      }
    }

    &.active {
      box-shadow:
        0 20px 20px rgba(105, 123, 140, 0.1),
        0 10px 10px rgba(105, 123, 140, 0.05);
      border-color: #0dc4e0;

      .card-select {
        &__top {
          box-shadow: none;
          border-top: none;
          border-left: none;
          border-right: none;
          border-color: #d8e4eb;
          border-radius: 8px 8px 0 0;

          &:hover {
            border-color: #d8e4eb;
          }
        }
      }
    }
  }
}
