@import "src/styles/includes/colors";

.card-input {
  display: flex;

  &__wrapper {
    width: 100%;

    .max {
      background: #ffffff;
      border: 1px solid #d8e4eb;
      box-sizing: border-box;
      box-shadow: 0 2px 6px rgba(0, 111, 128, 0.08);
      border-radius: 8px;
      color: $turquoise-dark;
      font-weight: bold;
      font-size: 14px;
      line-height: 85%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-width: 90px;
      height: 48px;
      margin-left: 10px;
      transition:
        background 0.2s ease,
        border-color 0.2s ease;

      &:hover {
        background: #dcf9fb;
        border: 1px solid #50e0ed;
      }
    }
  }

  input {
    background: #f0f5f7;
    border-radius: 8px;
    font-size: 16px;
    line-height: 150%;
    padding: 12px 20px;
    width: 100%;
    transition:
      background 0.2s ease,
      border-color 0.2s ease,
      color 0.2s ease;
    border: 1px solid transparent;

    &:hover:not(:disabled) {
      border-color: #b8c4cc;
    }

    &:focus {
      background: #ffffff;
      border: 1px solid $turquoise-dark;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .input {
    width: 100%;
    position: relative;
    border-radius: 8px;

    &--suffix {
      padding-right: 86px;
    }

    .suffix {
      position: absolute;
      top: 50%;
      right: 9px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }

      span {
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
      }
    }

    input.has-suffix {
      padding-right: 68px;
    }
  }

  &--white {
    input {
      background: #fff;
      border-color: $gray-3;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
