@keyframes airdropRing {
  0% {
    width: 10%;
    height: 10%;
    opacity: 1;
  }
  100% {
    width: 130%;
    height: 130%;
    opacity: 0;
  }
}
