:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #eef5f9;
  --toastify-color-success: #e6f9ee;
  --toastify-color-warning: #fff7e9;
  --toastify-color-error: #f6eded;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 350px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  // right: 50px;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  // width: 350px;
  min-height: 104px;
  padding: 24px;

  box-sizing: border-box;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.Toastify__toast--rtl {
}

.Toastify__toast-body {
  align-items: flex-start;
  padding: 0;
  margin: 0;
}

/** Used to position the icon **/
.Toastify__toast-icon {
}

/** handle the notificaiton color and the text color based on the theme **/
.Toastify__toast-theme--dark {
}
.Toastify__toast-theme--light {
  // background: #E6F9EE;
  // border: 2px solid #AAEDC6;
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: #eef5f9;
  border: 2px solid #abd2e9;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #eef5f9;
  border: 2px solid #abd2e9;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #e6f9ee;
  border: 2px solid #aaedc6;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #fff7e9;
  border: 2px solid #ffdfaa;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #f6eded;
  border: 2px solid #e7b3b3;
}

.Toastify__progress-bar {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
}
.Toastify__progress-bar-theme--dark {
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
}
/** colored notifications share the same progress bar color **/
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

.ToastPending {
  cursor: default !important;
}
