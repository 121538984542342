@import "src/styles/includes/colors";

.card-tabs {
  border-bottom: 1px solid $gray-3;
  margin-bottom: 20px;

  &__item {
    color: #adadad;
    font-weight: 500;
    padding: 20px;
  }

  ul {
    display: flex;
    margin-bottom: -1px;

    li {
      font-weight: bold;
      font-size: 16px;
      line-height: 85%;
      text-align: center;
      cursor: pointer;

      &.active {
        background:
          linear-gradient(#fff, #fff) padding-box,
          linear-gradient(90deg, #fff500 0%, #b6e82f 71.67%);
        border-bottom: 3px solid transparent;
        padding-bottom: 17px;
      }
    }
  }
}

@media (max-width: 360px) {
  .card-tabs {
    &__item {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
