*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Geometria, sans-serif;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #f8f8f8;
}

a {
  outline: none;
  text-decoration: none;
  color: black;
}

button,
a {
  cursor: pointer;
}

button {
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

input {
  border: none;
  outline: none;
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 100%;
  color: #000000;
  margin: 10px 20px 10px 0;
}

td {
  white-space: nowrap;
}

svg {
  outline: none;
}
