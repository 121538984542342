.dca-calendar-dropdown {
  filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.1));
  .ant-picker-date-panel {
    width: 500px;
  }
  .ant-picker-content {
    width: 383px;
    th {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #999999;
    }
  }
  .ant-picker-cell {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #cccccc;
  }
  .ant-picker-cell-in-view {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #444444;
  }
  .ant-picker-header-view {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  .ant-picker-panel-container {
    background: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 111, 128, 0.08);
    border-radius: 16px;
    padding-top: 20px;
  }
  .ant-picker-body {
    padding: 10px 58px;
  }
  .ant-picker-header {
    border-bottom: none;
  }
  .ant-picker-panel .ant-picker-footer {
    border-top: none;
  }
  .ant-picker-footer-extra:not(:last-child) {
    border-bottom: none;
  }
  .ant-picker-today-btn {
    display: none;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #000;
    background: #fff;
  }
  .ant-picker-dropdown {
    width: 500px;
  }
  .ant-picker-month-panel {
    width: 500px;
  }
  .ant-picker-year-panel {
    width: 500px;
  }
  .ant-picker-decade-panel {
    width: 500px;
  }
  .ant-picker-header-next-btn {
    display: none;
  }
  .ant-picker-header-super-next-btn {
    display: none;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    display: none;
  }
  .ant-picker-header-super-prev-btn {
    display: none;
  }
  .ant-picker-header-prev-btn {
    display: none;
  }
  tbody {
    tr {
      td {
        padding-top: 15px;
      }
    }
    tr:first-of-type {
      td {
        padding-top: 24px;
      }
    }
  }
}

.dca-ant-picker {
  border-radius: 10px;
  padding: 8px 17px 8px;
  border: 1px solid #858585;
  &:hover {
    border-color: #858585;
  }
  &.ant-picker-focused {
    border-color: unset;
    box-shadow: unset;
  }
}

@media (max-width: 800px) {
  .dca-calendar-dropdown {
    .ant-picker-date-panel {
      width: 100%;
    }
    .ant-picker-body {
      padding: 10px 0;
    }
    .ant-picker-content {
      width: 100%;
    }
    .ant-picker-dropdown {
      width: 100%;
    }
    .ant-picker-month-panel {
      width: 100%;
    }
    .ant-picker-year-panel {
      width: 100%;
    }
    .ant-picker-decade-panel {
      width: 100%;
    }
  }
}
