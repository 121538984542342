.tooltip {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: max-content;

  &-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &-content {
    position: absolute;
    top: -15px;
    left: calc(100% + 5px);
    width: 228px;
    background: #ffffff;
    border: 1px solid #eff4f5;
    box-shadow: 0 4px 8px 0 rgba(47, 128, 237, 0.1);
    border-radius: 8px;
    z-index: 9999;
    transition:
      visibility 150ms linear,
      opacity 150ms linear;
    padding: 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    &::before {
      position: absolute;
      left: -4px;
      top: 19px;
      width: 8px;
      height: 8px;
      z-index: 9998;
      content: "";
      border-left: 1px solid #eff4f5;
      border-bottom: 1px solid #eff4f5;
      transform: rotate(45deg);
      background: #ffffff;
    }
  }
  &-liquidity {
    .tooltip-content {
      width: 320px;
    }
  }

  &--left {
    .tooltip {
      &-content {
        left: unset;
        right: calc(100% + 5px);
      }
    }
  }
  &--up {
    .tooltip {
      &-content {
        left: unset;
        right: unset;
        bottom: calc(100% + 5px);
        top: unset;
      }
    }
  }
  &--down {
    .tooltip {
      &-content {
        left: unset;
        right: unset;
        top: calc(100% + 5px);
        bottom: unset;
      }
    }
  }
}
